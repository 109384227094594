<template>
  <v-container>
    <v-card class="mx-auto" width="500" color="#F5EFE7">
      <v-card-title
        class="justify-center font-weight-bold"
        style="color: #213555"
      >
        在開始測試前，請先填寫以下資料
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row no-gutters>
            <v-col class="pa-2" cols="12" sm="6">
              <v-text-field
                label="身高"
                v-model="height"
                autocomplete="off"
                :rules="heightRules"
                outlined
                dense
                @click="showNumKeyboard('height')"
              ></v-text-field>
            </v-col>
            <v-col class="pa-2" cols="12" sm="6">
              <v-text-field
                label="體重"
                v-model="weight"
                autocomplete="off"
                :rules="weightRules"
                outlined
                dense
                @click="showNumKeyboard('weight')"
              ></v-text-field>
            </v-col>
            <v-col class="pa-2" cols="12" sm="6">
              <v-text-field
                label="年齡"
                v-model="age"
                autocomplete="off"
                :rules="ageRules"
                outlined
                dense
                @click="showNumKeyboard('age')"
              ></v-text-field>
            </v-col>
            <v-col class="pa-2" cols="12" sm="6">
              <v-radio-group v-model="gender" row dense>
                <v-radio label="男" value="male" class="mr-4"></v-radio>
                <v-radio label="女" value="female"></v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col class="pa-2" cols="12">
              <div>
                <v-btn @click="connectToDevice" :disabled="connected">
                  連接藍牙裝置
                </v-btn>
                <v-btn @click="disconnectFromDevice" :disabled="!connected">
                  斷開藍牙裝置
                </v-btn>
                <div v-if="dataAvailable">接收數據: {{ receivedData }}</div>
              </div>
            </v-col> -->
          </v-row>
          <v-btn class="mt-4" color="#4f709c" dark block @click="submitForm">
            送出
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showNumericKeypad" max-width="400px">
      <div class="keyboard">
        <v-text-field
          class="mt-4 mx-4"
          dense
          hint="0"
          v-model="defaultValue"
          hide-details
          outlined
        ></v-text-field>
        <div class="keyboard_key">
          <div class="keyboard_number" @click="handleKeyPress">
            <div class="pos-right-pad-num">
              <button
                type="button"
                class="pad-num"
                :data-num="item"
                v-for="(item, index) in numArr"
                :key="index"
              >
                {{ item }}
              </button>
              <button type="button" class="pad-num--max" data-num="0">0</button>
              <button type="button" class="pad-num" data-num=".">.</button>
            </div>
          </div>
          <div class="pos-right-pad-act" @click="handleExecBtnPress">
            <button
              type="button"
              class="pad-num special-key border-right exit"
              data-num="exit"
            >
              退出
            </button>
            <button
              type="button"
              class="pad-num special-key border-right clear-all"
              data-num="delAll"
            >
              清空
            </button>
            <button
              type="button"
              class="pad-num special-key border-right sure"
              data-num="sure"
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      height: "",
      weight: "",
      age: "",
      gender: "",
      heightRules: [
        (v) => !!v || "請填寫身高",
        (v) => (Number(v) > 0 && Number(v) < 300) || "請輸入有效的身高",
      ],
      weightRules: [
        (v) => !!v || "請填寫體重",
        (v) => (Number(v) > 0 && Number(v) < 300) || "請輸入有效的體重",
      ],
      ageRules: [
        (v) => !!v || "請填寫年齡",
        (v) => (Number(v) > 0 && Number(v) < 150) || "請輸入有效的年齡",
      ],
      showNumericKeypad: false,
      typing: "",
      defaultValue: "",
      numArr: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      //連接測試
      connected: false,
      device: null,
      server: null,
      characteristic: null,
      dataAvailable: false,
      receivedData: null,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: "SixmwtDashboard",
          params: {
            height: this.height,
            weight: this.weight,
            age: this.age,
            gender: this.gender,
          },
        });
      }
    },
    showNumKeyboard(label) {
      this.typing = label;
      switch (this.typing) {
        case "height":
          this.defaultValue = this.height;
          break;
        case "weight":
          this.defaultValue = this.weight;
          break;
        case "age":
          this.defaultValue = this.age;
          break;
      }
      this.showNumericKeypad = true;
    },
    handleKeyPress(e) {
      const num = e.target.dataset.num;
      this.addNum(num);
    },
    handleExecBtnPress(e) {
      const num = e.target.dataset.num;
      console.log(num);
      if (num) {
        if (num === "sure") {
          switch (this.typing) {
            case "height":
              this.height = this.defaultValue;
              break;
            case "weight":
              this.weight = this.defaultValue;
              break;
            case "age":
              this.age = this.defaultValue;
              break;
          }
          this.deleteAllKey();
          this.showNumericKeypad = false;
          return;
        }
        if (num === "exit") {
          this.deleteAllKey();
          this.showNumericKeypad = false;
          return;
        }
        switch (String(num)) {
          case "delAll":
            this.deleteAllKey();
            break;
          default:
            this.addNum(num);
            break;
        }
      }
    },
    deleteAllKey() {
      this.defaultValue = "";
      console.log(this.defaultValue);
    },
    addNum(num) {
      const value = this.defaultValue;
      if (num !== undefined) {
        this.defaultValue = value + num;
      }
    },
    //連線測試
    // async connectToDevice() {
    //   try {
    //     const device = await navigator.bluetooth.requestDevice({
    //       filters: [{ namePrefix: "FORA" }],
    //       optionalServices: ["00001523-1212-efde-1523-785feabcd123"],
    //     });
    //     let server = await device.gatt.connect();
    //     this.connected = true;
    //     this.device = device;
    //     this.deviceName = device.name;
    //     this.deviceStatus = "已連接";
    //     const service = await server.getPrimaryService(
    //       "00001523-1212-efde-1523-785feabcd123"
    //     );
    //     const characteristic = await service.getCharacteristic(
    //       "00001524-1212-efde-1523-785feabcd123"
    //     );
    //     console.log(
    //       "🚀 ~ file: SixmwtProfile.vue:257 ~ connectToDevice ~ characteristic:",
    //       characteristic
    //     );
    //     // let value = await characteristic.readValue();
    //     // console.log(value);
    //     characteristic.addEventListener(
    //       "characteristicvaluechanged",
    //       (event) => {
    //         const value = event.target.value;
    //         console.log("體溫:", value);
    //       }
    //     );
    //     await characteristic.startNotifications();
    //   } catch (error) {
    //     console.error("連接藍牙裝置時發生錯誤:", error);
    //   }
    // },
    // async disconnectFromDevice() {
    //   try {
    //     if (this.device && this.device.gatt.connected) {
    //       await this.device.gatt.disconnect();
    //     }
    //     this.connected = false;
    //     this.device = null;
    //     this.deviceName = "";
    //     this.deviceStatus = "";
    //   } catch (error) {
    //     console.error("斷開藍牙裝置時發生錯誤:", error);
    //   }
    // },
    async connectToDevice() {
      try {
        this.device = await navigator.bluetooth.requestDevice({
          filters: [{ namePrefix: "FORA" }],
        });
        this.server = await this.device.gatt.connect();
        const service = await this.server.getPrimaryService(
          "00001523-1212-efde-1523-785feabcd123"
        );
        this.characteristic = await service.getCharacteristic(
          "00001524-1212-efde-1523-785feabcd123"
        );
        this.connected = true;
        this.startNotifications();
      } catch (error) {
        console.error("連接到裝置時發生錯誤:", error);
      }
    },
    async disconnectFromDevice() {
      if (this.characteristic) {
        await this.characteristic.stopNotifications();
        this.characteristic = null;
      }

      if (this.server) {
        await this.server.disconnect();
        this.server = null;
      }

      this.device = null;
      this.connected = false;
      this.dataAvailable = false;
      this.receivedData = null;
    },
    async startNotifications() {
      try {
        await this.characteristic.startNotifications();
        this.characteristic.addEventListener(
          "characteristicvaluechanged",
          this.handleCharacteristicValueChanged
        );
        console.log("訂閱通知配置:", this.characteristic);
      } catch (error) {
        console.error("啟動通知時發生錯誤:", error);
      }
    },
    handleCharacteristicValueChanged(event) {
      console.log(event);
      const value = event.target.value;
      // 接收到數據
      this.receivedData = value;
      this.dataAvailable = true;
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  padding: 0 !important;
}
.keyboard {
  width: 400px;
  height: 400px;
  background: #dbdbdb;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000,endColorstr=#99000000);
  box-shadow: 0 0 10px rgb(43, 42, 42);
  padding: 10px;
  border-radius: 10px;
  user-select: none;
  -ms-user-select: none;
}
.keyboard button {
  background-color: #e6fffd;
}
.keyboard_key {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.keyboard_number {
  display: flex;
  width: 75%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.pos-right-pad-num {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.pos-right-pad-act {
  width: 25%;
}
.pad-num {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  font-weight: bold;
  margin: 10px;
  float: left;
  box-shadow: 0px 2px 1px #000;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}
.pad-num--max {
  width: 140px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  font-weight: bold;
  margin: 10px;
  float: left;
  box-shadow: 0px 2px 1px #000;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}
.exit {
  color: #f44336;
}
.del {
  color: #ff9800;
}
.clear--all {
  color: #2196f3;
}
.sure {
  width: 190px;
  color: #4caf50;
}
.special-key {
  background: #fff;
  width: 100%;
}
</style>
