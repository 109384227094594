<template>
  <v-row align="center" justify="center" no-gutters style="height: 100%">
    <v-card class="pa-8 ma-4" elevation="24">
      <v-card-title class="justify-center text-h4 font-weight-bold">
        機構登入
      </v-card-title>
      <v-card-text class="py-2 pa-sm-4">
        <!-- <v-form ref="form" lazy-validation> -->
        <!-- <v-text-field
            label="帳號"
            prepend-icon="mdi-account"
            v-model="account"
            autofocus
          /> -->
        <!-- 26620105 -->
        <v-text-field
          label="請輸入認證碼"
          prepend-icon="mdi-lock"
          :type="showCode ? 'text' : 'password'"
          :append-icon="showCode ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="code"
          @click:append="showCode = !showCode"
          @keyup.enter="login"
          autofocus
        />
        <!-- </v-form> -->
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="login"
              block
              class="font-weight-bold"
            >
              登入
            </v-btn>
          </v-col>
          <!-- <v-col class="text-center pa-2" cols="12">
            <v-btn text color="light-blue">忘記密碼?</v-btn>
          </v-col> -->
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      code: "",
      showCode: false,
    };
  },
  methods: {
    async login() {
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify({
        code: this.code,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/attendance/worker_login",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        if (res.data.status === "success") {
          this.id = res.data.data;
          let data = {
            id: this.id,
          };
          sessionStorage.setItem("staff", JSON.stringify(data));
          sessionStorage.setItem("isStaffLogin", true);
          this.$router.replace("/");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
