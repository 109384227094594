var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2 my-auto"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.residents),function(resident){return _c('v-col',{key:resident.case_num,staticClass:"pa-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"pa-1",class:_vm.cardClass(resident.status),attrs:{"height":"100%","hover":""}},[_c('v-card-title',{staticClass:"pa-0 pa-sm-2 justify-center text-h6 font-weight-bold"},[_c('span',{staticClass:"card-title"},[_vm._v(" "+_vm._s(resident.name)+" ")]),_c('v-icon',{staticClass:"lights",style:(resident.status ? 'color:#008055' : ''),on:{"click":function($event){$event.stopPropagation();return _vm.manualDialog(
                    resident.case_num,
                    resident.name,
                    resident.status,
                    _vm.group,
                    _vm.index
                    )}}})],1),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-icon',{style:(!resident.uncomfortable ? 'color:#53BF9D' : 'color:red')},[_vm._v(" "+_vm._s(!resident.uncomfortable ? "mdi-emoticon-outline" : "mdi-emoticon-frown-outline")+" ")])],1),_c('div',{staticClass:"text-center text-caption"},[_vm._v("身體狀況")])]),_c('v-card-text',{staticClass:"px-0 py-2 justify-center text-subtitle-1 font-weight-bold black--text"},[_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-icon',{attrs:{"color":"#F94C66"}},[_vm._v("mdi-heart")]),(resident.heart_rate != '')?_c('div',[_vm._v(_vm._s(resident.heart_rate)+" bpm")]):_c('div',[_vm._v("無資料")])],1)],1)],1)],1)],1)],1)],1)],1)}),1),_c('v-snackbar',{attrs:{"centered":"","timeout":"750","color":_vm.snackColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticClass:"font-weight-bold text-center",staticStyle:{"color":"#f6f1f1"}},[_c('span',[_vm._v(_vm._s(_vm.check_response))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }