<template>
  <div class="ma-auto">
    <v-row no-gutters justify="center" align="center">
      <v-col
        class="text-h4 pa-4 font-weight-bold text-center"
        style="color: #643a6b"
        cols="12"
      >
        生理數據 - {{ name }}
      </v-col>
      <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
        <ChartDailyHR :dataDailyHr="dataDailyHr" :labelDailyHr="labelDailyHr" />
      </v-col>
      <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
        <ChartBP :labelHr="labelHr" />
      </v-col>
      <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
        <ChartCH :dataHr="dataHr" :dataCal="dataCal" :labelHr="labelHr" />
      </v-col>
      <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
        <ChartDFS
          :dataDis="dataDis"
          :dataFloor="dataFloor"
          :dataStep="dataStep"
          :labelStep="labelStep"
        />
      </v-col>
      <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
        <ChartSleep
          :dataAwake="dataAwake"
          :dataDeep="dataDeep"
          :dataLight="dataLight"
          :dataRem="dataRem"
          :labelSleep="labelSleep"
        />
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import ChartBP from "@/components/ChartBP.vue";
import ChartCH from "@/components/ChartCH.vue";
import ChartDailyHR from "@/components/ChartDailyHR.vue";
import ChartDFS from "@/components/ChartDFS.vue";
import ChartSleep from "@/components/ChartSleep.vue";
export default {
  components: { ChartBP, ChartCH, ChartDailyHR, ChartDFS, ChartSleep },
  data() {
    return {
      id: "",
      name: "",
      dataAwake: [],
      dataCal: [],
      dataDailyHr: [],
      dataDeep: [],
      dataDis: [],
      dataFloor: [],
      dataHr: [],
      dataLight: [],
      dataRem: [],
      dataStep: [],
      labelDailyHr: [],
      labelHr: [],
      labelSleep: [],
      labelStep: [],
    };
  },
  methods: {
    async getWeeklyData(id) {
      const axios = require("axios");
      // const start = moment().subtract(6, "days").format("YYYY-MM-DD");
      // const end = moment().format("YYYY-MM-DD");
      const start = moment().subtract(8, "days").format("YYYY-MM-DD");
      const end = moment().subtract(2, "days").format("YYYY-MM-DD");
      let config = {
        method: "get",
        url:
          "https://ntu-med-god.ml/api/getUserFitbitByRange?id=" +
          id +
          "&start=" +
          start +
          "&end=" +
          end,
        header: {},
      };
      try {
        let res = await axios(config);
        // console.log(res.data);
        if (res.data.length > 0) {
          res.data.forEach((element) => {
            switch (element.name) {
              case "calories":
                this.dataCal = element.value.map((item) =>
                  Math.round(item.sum)
                );
                break;
              case "distance":
                this.dataDis = element.value.map((item) =>
                  Math.round(item.sum)
                );
                break;
              case "floors":
                this.dataFloor = element.value.map((item) => item.sum);
                break;
              case "steps":
                this.labelStep = element.value.map((item) =>
                  moment(item.time).format("MM-DD")
                );
                this.dataStep = element.value.map((item) => item.sum);
                break;
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getWeeklyHeartRate(id) {
      const axios = require("axios");
      // const start = moment().subtract(6, "days").format("YYYY-MM-DD");
      // const end = moment().format("YYYY-MM-DD");
      const start = moment().subtract(8, "days").format("YYYY-MM-DD");
      const end = moment().subtract(2, "days").format("YYYY-MM-DD");
      let config = {
        method: "get",
        url:
          "https://ntu-med-god.ml/api/getHeartRateMeanByRange?id=" +
          id +
          "&start=" +
          start +
          "&end=" +
          end,
        header: {},
      };
      try {
        let res = await axios(config);
        // console.log(res.data);
        if (res.data.length > 0) {
          this.labelHr = res.data.map((item) =>
            moment(item.time).format("MM-DD")
          );
          this.dataHr = res.data.map((item) => Math.round(item.mean));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getWeeklySleep(id) {
      const axios = require("axios");
      // const start = moment().subtract(6, "days").format("YYYY-MM-DD");
      // const end = moment().format("YYYY-MM-DD");
      const start = moment().subtract(8, "days").format("YYYY-MM-DD");
      const end = moment().subtract(2, "days").format("YYYY-MM-DD");
      let config = {
        method: "get",
        url:
          "https://ntu-med-god.ml/api/getSumSleepByRange?id=" +
          id +
          "&start=" +
          start +
          "&end=" +
          end,
        header: {},
      };
      try {
        let res = await axios(config);
        if (res.data.wake.length > 0) {
          this.dataAwake = res.data.wake.map((item) => item.sum);
        }
        if (res.data.wake.length > 0) {
          this.dataDeep = res.data.deep.map((item) => item.sum);
        }
        if (res.data.light.length > 0) {
          this.labelSleep = res.data.light.map((item) =>
            moment(item.time).format("MM-DD")
          );
          this.dataLight = res.data.light.map((item) => item.sum);
        }
        if (res.data.wake.length > 0) {
          this.dataRem = res.data.rem.map((item) => item.sum);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDailyHeartRate(id) {
      const axios = require("axios");
      // const date = moment().format("YYYY-MM-DD");
      const date = moment().subtract(2, "days").format("YYYY-MM-DD");
      let config = {
        method: "get",
        url:
          "https://ntu-med-god.ml/api/getDailyHeartRate?id=" +
          id +
          "&date=" +
          date,
        header: {},
      };
      try {
        let res = await axios(config);
        // console.log(res.data);
        if (res.data.length > 0) {
          this.labelDailyHr = res.data.map((item) =>
            moment(item.time).format("HH:mm")
          );
          this.dataDailyHr = res.data.map((item) => item.value);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getName(id) {
      const cases = [
        {
          name: "吳佳東",
          id: "k87j6e7c",
        },
        {
          name: "謝宗廷",
          id: "lhq7k3id",
        },
        {
          name: "蘇凱正",
          id: "lhq7oyab",
        },
        {
          name: "王思敏",
          id: "ljb3m0fp",
        },
        {
          name: "吳佳定",
          id: "l0m0twvp",
        },
        {
          name: "黃O冠",
          id: "l0m0roe0",
        },
        {
          name: "李O燿",
          id: "lhpqjzva",
        },
        {
          name: "曾O維",
          id: "li3z9fac",
        },
        {
          name: "張淑美",
          id: "leocq26v",
        },
        {
          name: "林oo娥",
          id: "ldo7lp3a",
        },
        {
          name: "龔oo美",
          id: "l8mrvsve",
        },
        {
          name: "李oo梅",
          id: "kfp1r5hi",
        },
        {
          name: "詹oo枝",
          id: "l0g7ah5u",
        },
        {
          name: "林o桃",
          id: "lhq7z590",
        },
        {
          name: "陳o造",
          id: "lhq2a592",
        },
        {
          name: "鄭o本",
          id: "ld1ce6qy",
        },
        {
          name: "鄭oo鳳",
          id: "le9hs1d7",
        },
        {
          name: "彭oo霞",
          id: "lfamgnyi",
        },
      ];
      const foundCase = cases.find((caseItem) => caseItem.id === id);
      this.name = foundCase.name;
    },
  },
  mounted() {
    this.getName(this.id);
    this.getWeeklyData(this.id);
    this.getWeeklyHeartRate(this.id);
    this.getWeeklySleep(this.id);
    this.getDailyHeartRate(this.id);
  },
  created() {
    this.id = this.$route.params.id;
    console.log("🚀 ~ file: Dashboard.vue:217 ~ created ~ this.id:", this.id);
  },
};
</script>
<style scoped></style>
