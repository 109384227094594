<template>
  <div ref="chart" style="width: 600px; height: 400px"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["dataCal", "dataHr", "labelHr"],
  data() {
    return {};
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "line", snap: true },
        },
        legend: {},
        xAxis: {
          type: "category",
          data: this.labelHr,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "心率",
            position: "left",
          },
          {
            type: "value",
            name: "卡路里",
            position: "right",
            splitLine: {
              show: false,
            },
            min: 0,
            max: 10000,
          },
        ],
        series: [
          {
            name: "平均心率",
            yAxisIndex: 0,
            data: this.dataHr,
            color: "#B70404",
            type: "line",
            smooth: true,
            label: {
              show: true,
              position: "top",
            },
          },
          {
            name: "卡路里",
            yAxisIndex: 1,
            data: this.dataCal,
            color: "#04b7b7",
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
          },
        ],
      };
      chart.setOption(option);
    },
  },
  watch: {
    dataCal() {
      this.renderChart();
    },
    dataHr() {
      this.renderChart();
    },
    labelHr() {
      this.renderChart();
    },
  },
};
</script>
