<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          今日
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="menu = !menu"
              elevation="0"
            >
              {{ formattedDate }}
            </v-btn>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="hover-effect">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.check_in`]="{ item, index }">
          <span
            v-if="item.check_in == null"
            @click="manual_check(index, 'in')"
            class="text-add hover-add"
            @mouseover="colorItems.check_in = '#258525'"
            @mouseout="colorItems.check_in = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect">
            {{ covertToLocalTime(item.check_in) }}
          </span>
        </template>
        <template v-slot:[`item.check_out`]="{ item, index }">
          <span
            v-if="item.check_out == null"
            @click="manual_check(index, 'out')"
            class="text-add hover-add"
            @mouseover="colorItems.check_out = '#258525'"
            @mouseout="colorItems.check_out = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect">
            {{ covertToLocalTime(item.check_out) }}
          </span>
        </template>
        <template v-slot:[`item.temperature`]="{ item, index }">
          <span
            v-if="item.temperature == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.temperature = '#258525'"
            @mouseout="colorItems.temperature = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.temperature }}
          </span>
        </template>
        <template v-slot:[`item.hr`]="{ item, index }">
          <span
            v-if="item.hr == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.hr = '#258525'"
            @mouseout="colorItems.hr = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.hr }}
          </span>
        </template>
        <template v-slot:[`item.bloodPressure`]="{ item, index }">
          <span
            v-if="item.shrink == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.shrink = '#258525'"
            @mouseout="colorItems.shrink = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.shrink }}/{{ item.relax }}
          </span>
        </template>
        <template v-slot:[`item.SPO2`]="{ item, index }">
          <span
            v-if="item.SPO2 == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.SPO2 = '#258525'"
            @mouseout="colorItems.SPO2 = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.SPO2 }}
          </span>
        </template>
        <template v-slot:[`item.blood_sugar`]="{ item, index }">
          <span
            v-if="item.blood_sugar == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.blood_sugar = '#258525'"
            @mouseout="colorItems.blood_sugar = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.blood_sugar }}
          </span>
        </template>
        <template v-slot:[`item.weight`]="{ item, index }">
          <span
            v-if="item.weight == null"
            @click="enterVitalSign(index)"
            class="text-add hover-add"
            @mouseover="colorItems.weight = '#258525'"
            @mouseout="colorItems.weight = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect" @click="editVitalSign(index)">
            {{ item.weight }}
          </span>
        </template>
        <template v-slot:[`item.history`]="{ item, index }">
          <v-btn @click="viewVitalSignHistory(index)" class="hover-effect">
            查看
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog persistent v-model="sign_dialog" max-width="400px">
      <v-card>
        <v-card-title class="justify-center">
          簽到退紀錄 - {{ caseName }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                dense
                outlined
                :items="select_item"
                v-model="sign_status"
                class="pa-2"
                hide-details=""
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="選擇日期"
                v-model="date"
                type="date"
                prepend-icon="mdi-calendar"
                :rules="dateRules"
                class="my-2"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="選擇時間"
                v-model="time"
                type="time"
                prepend-icon="mdi-clock-time-four-outline"
                :rules="timeRules"
                class="my-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="sign_dialog = false">取消</v-btn>
            <v-btn text color="blue" @click="onCheck">確認</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="justify-center">
          {{ caseName }} 的健康數據
          <v-spacer />
          <v-btn
            v-if="exist"
            color="#614BC3"
            class="white--text"
            @click="enterVitalSign(index)"
          >
            ✚新增資料
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <!-- <v-text-field
              filled
              label="時間"
              :value="time"
              readonly
              hide-details=""
              class="my-2"
            ></v-text-field> -->
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="選擇日期"
                  v-model="date"
                  type="date"
                  prepend-icon="mdi-calendar"
                  :rules="dateRules"
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="選擇時間"
                  v-model="time"
                  type="time"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="timeRules"
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="體溫"
                  v-model="temperature"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="心率"
                  v-model="hr"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="收縮壓"
                  v-model="shrink"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="舒張壓"
                  v-model="relax"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="血氧"
                  v-model="spo2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="血糖"
                  v-model="bs"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  dense
                  outlined
                  hide-details=""
                  label="體重"
                  v-model="weight"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <!-- <v-btn text color="red" @click="dialog = false">拒測</v-btn> -->
            <v-btn v-if="exist" text color="blue" @click="dialog = false"
              >確認</v-btn
            >
            <v-btn v-else text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="history_dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline"
          >{{ caseName }} 的歷史健康數據
          <v-btn color="green darken-1" text @click="history_dialog = false"
            >關閉</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row
              dense
              style="border: 1px solid gray; margin-bottom: 2px"
              v-for="record in vitalSignHistory"
              :key="record.vital_sign_id"
            >
              <v-col cols="12" sm="6">
                <v-text-field
                  label="紀錄時間"
                  :value="formatDate(record.record_datetime)"
                  prepend-icon="mdi-calendar"
                  readonly
                  class="my-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- empty for page view -->
              </v-col>

              <v-col cols="12" sm="3" v-if="record.temperature !== null">
                <v-text-field
                  dense
                  outlined
                  label="體溫"
                  :value="record.temperature"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.hr !== null">
                <v-text-field
                  dense
                  outlined
                  label="心率"
                  :value="record.hr"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="3"
                v-if="record.shrink !== null && record.relax !== null"
              >
                <v-text-field
                  dense
                  outlined
                  label="血壓"
                  :value="`${record.shrink}/${record.relax}`"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.SPO2 !== null">
                <v-text-field
                  dense
                  outlined
                  label="血氧"
                  :value="record.SPO2"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.blood_sugar !== null">
                <v-text-field
                  dense
                  outlined
                  label="血糖"
                  :value="record.blood_sugar"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="record.weight !== null">
                <v-text-field
                  dense
                  outlined
                  label="體重"
                  :value="record.weight"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          width: "100px",
        },
        { text: "簽到", value: "check_in", align: "center", width: "75px" },
        { text: "簽退", value: "check_out", align: "center", width: "75px" },
        { text: "體溫", value: "temperature", align: "center", width: "75px" },
        { text: "心率", value: "hr", align: "center", width: "75px" },
        {
          text: "血壓",
          value: "bloodPressure",
          align: "center",
          width: "75px",
        },
        { text: "血氧", value: "SPO2", align: "center", width: "75px" },
        { text: "血糖", value: "blood_sugar", align: "center", width: "75px" },
        { text: "體重", value: "weight", align: "center", width: "75px" },
        { text: "歷史紀錄", value: "history", align: "center", width: "75px" },
      ],
      records: [],
      sign_dialog: false,
      dialog: false,
      exist: false,
      staff_id: "",
      case_id: "",
      index: "",
      caseName: "",
      date: "",
      time: "",
      temperature: "",
      hr: "",
      shrink: "",
      relax: "",
      bs: "",
      spo2: "",
      weight: "",
      colorItems: {
        check_in: "#8d8d8d",
        check_out: "#8d8d8d",
        temperature: "#8d8d8d",
        hr: "#8d8d8d",
        shrink: "#8d8d8d",
        bloodOxygen: "#8d8d8d",
        blood_sugar: "#8d8d8d",
        weight: "#8d8d8d",
      },
      menu: false,
      selectedDate: new Date(),
      dateRules: [(v) => !!v || "此欄位不可為空"],
      timeRules: [(v) => !!v || "此欄位不可為空"],
      select_item: ["簽到", "簽退"],
      sign_status: "",
      vitalSignHistory: [],
      history_dialog: false,
    };
  },
  methods: {
    async getList() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_vital_sign?date=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.records = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    enterVitalSign(index) {
      this.index = index;
      this.getCurrentTime();
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      this.temperature = "";
      this.hr = "";
      this.shrink = "";
      this.relax = "";
      this.spo2 = "";
      this.bs = "";
      this.weight = "";
      this.exist = false;
      this.dialog = true;
    },
    editVitalSign(index) {
      this.index = index;
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      console.log(this.records[index].record_datetime);
      let dateTime = new Date(this.records[index].record_datetime);
      let localDateTime = moment.utc(dateTime).local();
      this.date = localDateTime.format("YYYY-MM-DD");
      this.time = localDateTime.format("HH:mm");
      this.temperature = this.records[index].temperature;
      this.hr = this.records[index].hr;
      this.shrink = this.records[index].shrink;
      this.relax = this.records[index].relax;
      this.spo2 = this.records[index].SPO2;
      this.bs = this.records[index].blood_sugar;
      this.weight = this.records[index].weight;
      this.exist = true;
      this.dialog = true;
    },
    manual_check(index, status) {
      this.index = index;
      this.getCurrentTime();
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      if (status == "in") {
        this.sign_status = "簽到";
      } else {
        this.sign_status = "簽退";
      }
      this.sign_dialog = true;
    },
    async onCheck() {
      const axios = require("axios");
      const qs = require("qs");
      this.case_id = this.records[this.index].case_num;
      let formattedTime = moment(`${this.date}T${this.time}`).format();
      let data = qs.stringify({
        case_num: this.case_id,
        datetime: formattedTime,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/attendance/manual_check",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        this.getList();
        this.sign_dialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const axios = require("axios");
        const qs = require("qs");
        let formattedTime = moment(`${this.date}T${this.time}`).format();
        let data = qs.stringify({
          action: "create",
          employee_id: this.staff_id,
          temperature: this.temperature,
          hr: this.hr,
          shrink: this.shrink,
          relax: this.relax,
          blood_sugar: this.bs,
          SPO2: this.spo2,
          weight: this.weight,
          datetime: formattedTime,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `/api/attendance/vitalsigns/${this.case_id}/`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
        try {
          let res = await axios.request(config);
          console.log(res.data);
        } catch (error) {
          console.log(error);
        }
      }
      this.getList();
      this.dialog = false;
    },
    async viewVitalSignHistory(index) {
      const axios = require("axios");
      this.history_dialog = true;
      this.index = index;
      console.log(this.records[index]);
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      let config = {
        method: "get",
        url: `/api/attendance/vitalsigns/history/${this.case_id}/`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.vitalSignHistory = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getCurrentTime() {
      this.date = new Date().toISOString().substring(0, 10);
      this.time = new Date().toTimeString().substring(0, 5);
      // console.log(this.date + " " + this.time);
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    covertToLocalTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date
        .toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })
        .replace(",", ""); // 移除逗號
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    selectedDate() {
      this.getList();
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}
</style>
