<template>
  <div class="my-4">
    <div class="text-h5 text-center font-weight-bold" style="color: #b31312">
      <v-icon large color="#B31312">mdi-walk</v-icon>
      六分鐘行走測試
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped></style>
