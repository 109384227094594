<template>
  <div ref="chart" style="width: 600px; height: 400px"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["data", "label"],
  data() {
    return {};
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "line", snap: true },
        },
        legend: {},
        xAxis: {
          type: "category",
          data: this.label,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            name: "bpm",
            type: "value",
            position: "left",
          },
        ],
        series: [
          {
            name: "心率",
            data: this.data,
            color: "#B70404",
            type: "line",
            label: {
              show: true,
              position: "top",
            },
          },
        ],
      };
      chart.setOption(option);
    },
  },
  watch: {
    label() {
      this.renderChart();
    },
    data() {
      this.renderChart();
    },
  },
};
</script>
