<template>
  <div ref="chart" style="width: 600px; height: 400px"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["dataAwake", "dataDeep", "dataLight", "dataRem", "labelSleep"],
  data() {
    return {};
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", snap: true },
        },
        legend: {},
        xAxis: {
          type: "category",
          data: this.labelSleep,
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            name: "睡眠時數",
            type: "value",
            axisLabel: {
              formatter: "{value} 秒",
            },
          },
        ],
        series: [
          {
            name: "清醒",
            data: this.dataAwake,
            type: "line",
            stack: "Total",
            color: "#95BDFF",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
          },
          {
            name: "REM",
            data: this.dataRem,
            type: "line",
            stack: "Total",
            color: "#B4E4FF",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
          },
          {
            name: "深層",
            data: this.dataDeep,
            type: "line",
            stack: "Total",
            color: "#CAFFBF",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
          },
          {
            name: "淺層",
            data: this.dataLight,
            type: "line",
            stack: "Total",
            color: "#F7C8E0",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
          },
        ],
      };
      chart.setOption(option);
    },
  },
  watch: {
    dataAwake() {
      this.renderChart();
      // console.log(this.dataAwake);
    },
    dataDeep() {
      this.renderChart();
      // console.log(this.dataDeep);
    },
    dataLight() {
      this.renderChart();
      // console.log("light=" + this.dataLight);
    },
    dataRem() {
      this.renderChart();
      // console.log(this.dataRem);
    },
    labelSleep() {
      this.renderChart();
      // console.log(this.labelSleep);
    },
  },
};
</script>
