<template>
    <div class="pa-2 my-auto">
      <v-row dense>
        <v-col
          class="pa-2"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="resident in residents"
          :key="resident.case_num"
        >
            <!-- px-2 py-4 col col-6 in v-col  -->
                <v-card
                :class="cardClass(resident.status)"
                class="pa-1"
                height="100%"
                hover
                >
                <v-card-title
                    class="pa-0 pa-sm-2 justify-center text-h6 font-weight-bold"
                >
                    <span class="card-title">
                    {{ resident.name }}
                    </span>
                    <v-icon
                    class="lights"
                    :style="resident.status ? 'color:#008055' : ''"
                    @click.stop="
                        manualDialog(
                        resident.case_num,
                        resident.name,
                        resident.status,
                        group,
                        index
                        )
                    "
                    >
                    </v-icon>
                </v-card-title>
                <v-card-actions class="pa-0">
                    <v-row no-gutters align="center">
                    <v-col>
                        <div class="text-center">
                            <v-icon
                                :style="!resident.uncomfortable ? 'color:#53BF9D' : 'color:red'"
                            >
                                {{
                                !resident.uncomfortable ? "mdi-emoticon-outline" : "mdi-emoticon-frown-outline"
                                }}
                            </v-icon>
                        </div>
                        <div class="text-center text-caption">身體狀況</div>
                    </v-col>
                    <v-card-text
                        class="px-0 py-2 justify-center text-subtitle-1 font-weight-bold black--text"
                    >
                        <v-row no-gutters class="text-center">
                        <v-col cols="6">
                            <v-row no-gutters justify="center" align="center">
                            <v-icon color="#F94C66">mdi-heart</v-icon>
                            <div v-if="resident.heart_rate != ''">{{ resident.heart_rate }} bpm</div>
                            <div v-else>無資料</div>
                            </v-row>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-row>
                </v-card-actions>
                </v-card>
        </v-col>
      </v-row>
      <v-snackbar centered v-model="snackbar" timeout="750" :color="snackColor">
      <div class="font-weight-bold text-center" style="color: #f6f1f1">
          <span>{{ check_response }}</span>
      </div>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import qs from "qs";
  import moment from "moment";
  export default {
    data() {
      return {
        residents: [],
      };
    },
    methods: {
      cardClass(status) {
        return status ? "enabled-card" : "disabled-card";
      },
      manualDialog(caseNum, name, status, group, index) {
        // Implement your manualDialog method here
      },
      startPolling() {
        this.pollMessages();
      },
      pollMessages() {
        axios
          .get("/api/travelstate/get_status")
          .then((res) => {
            console.log(res.data);
            this.residents = res.data.data.residents;
            // this.sortCases();
            console.log(this.residents);
            this.interval = setTimeout(() => {
              this.pollMessages();
            }, 10000);
          })
          .catch((error) => {
            console.error("failed", error);
            this.interval = setTimeout(() => {
              this.pollMessages();
            }, 10000);
          });
      },
    },
    mounted() {
      // this.getHeartRate();
      this.startPolling();
    },
    beforeDestroy() {
      clearTimeout(this.interval);
    },
  };
  </script>
  
  <style scoped>
  .disabled-card {
    background-color: #a6a6a6;
    opacity: 0.5;
  }
  
  .enabled-card {
    background-color: #f9f9e1;
  }
  
  .card-title {
    color: #0079ff;
  }
  .lights {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  </style>