<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          本月
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevMonth"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn tile color="#BB2525" class="white--text" elevation="0">
              {{ formattedDate }}
            </v-btn>
            <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>  --> </v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextMonth"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, itemIndex) in items" :key="`row-${itemIndex}`">
              <td class="text-center">{{ item.name }}</td>
              <td
                v-for="(header, index) in headers.slice(1)"
                :key="`cell-${itemIndex}-${index}`"
                @click="editCarer(itemIndex, index)"
                class="text-add hover-add"
              >
                {{ item[header.value] || "x" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title> {{ caseName }} 的主責照服 </v-card-title>
        <v-card-text>
          <p class="ma-2">主責照服起始日</p>
          <v-text-field
            dense
            outlined
            hide-details=""
            placeholder="請選擇起始日期(yyyy-mm-dd)"
            v-model="editedData.editedStartDate"
            class="ma-2"
          ></v-text-field>
          <p class="ma-2">主責照服指派</p>
          <v-select
            dense
            outlined
            hide-details=""
            label="請選擇照服員"
            v-model="editedData.editedCaregiver"
            :items="caregiverOptions"
            class="ma-2"
          ></v-select>
          <!-- <v-text-field
            dense
            outlined
            hide-details=""
            placeholder="請選擇照服員"
            v-model="carer"
            class="ma-2"
          ></v-text-field> -->
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <v-btn v-if="exist" text color="blue" @click="dialog = false"
              >確認</v-btn
            >
            <v-btn v-else text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      headers: [],
      records: [],
      dialog: false,
      exist: false,
      staff_id: "",
      case_id: "",
      index: "",
      caseName: "",
      start_date: "",
      carer: "",
      menu: false,
      selectedDate: new Date(),
      caregiverOptions: [],
      editedData: {
        editedCaregiver: null,
        editedStartDate: null,
      },
    };
  },
  methods: {
    initialInputData() {
      for (let key in this.editedData) {
        if (this.editedData[key]) {
          this.editedData[key] = null;
        }
      }
    },
    async getList() {
      // console.log(this.formattedDate);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_service_record?month=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.records = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    editCarer(index, daysInMonth) {
      const day = daysInMonth + 1;
      const formattedDay = day < 10 ? `0${day}` : day;
      const date = `${this.formattedDate}-${formattedDay}`;
      this.editedData.editedStartDate = date;
      this.index = index;
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      this.dialog = true;
    },
    async submit() {
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify({
        caregiver: this.editedData.editedCaregiver,
        start_date: this.editedData.editedStartDate,
        case_num: this.case_id,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/attendance/add_service_record`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.initialInputData(); // clear input data
        this.dialog = false;
      } catch (error) {
        console.log(error);
      }
      this.getList();
    },
    getHeaders() {
      const daysInMonth = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        0
      ).getDate();
      const dateHeaders = [];
      for (let day = 1; day <= daysInMonth; day++) {
        dateHeaders.push({
          text: `${day}`,
          value: `${day}`,
          align: "center",
        });
      }
      this.headers = [
        { text: "姓名", value: "name", align: "center", width: "100px" },
        ...dateHeaders,
      ];
    },
    getCurrentTime() {
      this.time = new Date().toLocaleString();
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    prevMonth() {
      this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    nextMonth() {
      this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    async getCaregiverOptions() {
      // console.log(this.formattedDate);
      console.log(this.caregiverOptions);
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_caregiver`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.caregiverOptions = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        return `${year}-${month.toString().padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1);
      },
    },
  },
  mounted() {
    this.getHeaders();
    this.getList();
    this.getCaregiverOptions();
  },
  watch: {
    selectedDate() {
      this.getHeaders();
    },
    "editedData.editedStartDate": function (newValue, oldValue) {
      console.log(newValue);
      // 檢查newValue是否符合日期格式
      if (newValue.length === 4) {
        this.editedData.editedStartDate = newValue + "-";
      } else if (newValue.length === 7) {
        this.editedData.editedStartDate = newValue + "-";
      }
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}

::v-deep .v-data-table th {
  min-width: unset !important;
  padding: 0 !important;
}
::v-deep .v-data-table td {
  padding: 0 !important;
}
</style>
