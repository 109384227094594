<template>
  <div class="pa-2 ma=auto">
    <v-row dense align="center" class="py-4 justify-md-center">
      <v-col cols="6" md="3" class="pa-2">
        <v-hover v-slot="{ hover }">
          <v-card
            id="card"
            rounded="lg"
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            @click="getPicked((name = 'dashboard'), id)"
            color="#E8F6EF"
          >
            <v-img contain :src="require('@/assets/data.png')" class="pa-2" />
            <v-card-title
              class="justify-center pa-2 font-weight-bold text-body-2 text-sm-subtitle-1 text-md-h6"
            >
              查看生理數據
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="6" md="3" class="pa-2">
        <v-hover v-slot="{ hover }">
          <v-card
            id="card"
            rounded="lg"
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            @click="getPicked((name = 'sixmwt'), id)"
            color="#E8F6EF"
          >
            <v-img contain :src="require('@/assets/walk.png')" class="pa-2" />
            <v-card-title
              class="justify-center pa-2 font-weight-bold text-body-2 text-sm-subtitle-1 text-md-h6"
            >
              六分鐘行走測試
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      service: null,
    };
  },
  methods: {
    getPicked(name, id) {
      this.$router.push({ path: `${id}/${name}` });
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>

<style>
#card {
  transition: opacity 0.4s ease-in-out;
}

#card:not(.on-hover) {
  opacity: 0.4;
}
</style>
