<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          今日
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="menu = !menu"
              elevation="0"
            >
              {{ formattedDate }}
            </v-btn>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
    </v-row>

    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="hover-effect">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.data_time`]="{ item, index }">
          <span
            v-if="item.data_time == null"
            @click="enterDataTime(index)"
            class="text-add hover-add"
            @mouseover="colorItems.data_time = '#258525'"
            @mouseout="colorItems.data_time = '#8d8d8d'"
          >
            ✚新增
          </span>
          <span v-else class="hover-effect">
            {{ covertToLocalTime(item.data_time) }}
          </span>
        </template>
        <template v-slot:[`item.uric_acid`]="{ item, index }">
          <span
            v-if="item.uric_acid == null"
            class="text-add hover-add"
            @mouseover="colorItems.uric_acid = '#258525'"
            @mouseout="colorItems.uric_acid = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.uric_acid }}
          </span>
        </template>
        <template v-slot:[`item.calcium`]="{ item, index }">
          <span
            v-if="item.calcium == null"
            class="text-add hover-add"
            @mouseover="colorItems.calcium = '#258525'"
            @mouseout="colorItems.calcium = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.calcium }}
          </span>
        </template>
        <template v-slot:[`item.citrate`]="{ item, index }">
          <span
            v-if="item.citrate == null"
            class="text-add hover-add"
            @mouseover="colorItems.citrate = '#258525'"
            @mouseout="colorItems.citrate = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.citrate }}
          </span>
        </template>
        <template v-slot:[`item.creatinine`]="{ item, index }">
          <span
            v-if="item.creatinine == null"
            
            class="text-add hover-add"
            @mouseover="colorItems.creatinine = '#258525'"
            @mouseout="colorItems.creatinine = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.creatinine }}
          </span>
        </template>
        <template v-slot:[`item.magnesium`]="{ item, index }">
          <span
            v-if="item.magnesium == null"
            class="text-add hover-add"
            @mouseover="colorItems.magnesium = '#258525'"
            @mouseout="colorItems.magnesium = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.magnesium }}
          </span>
        </template>
        <template v-slot:[`item.sodium`]="{ item, index }">
          <span
            v-if="item.sodium == null"
            class="text-add hover-add"
            @mouseover="colorItems.sodium = '#258525'"
            @mouseout="colorItems.sodium = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.sodium }}
          </span>
        </template>
        <template v-slot:[`item.ph`]="{ item, index }">
          <span
            v-if="item.ph == null"
            class="text-add hover-add"
            @mouseover="colorItems.ph = '#258525'"
            @mouseout="colorItems.ph = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.ph }}
          </span>
        </template>
        <template v-slot:[`item.phosphate`]="{ item, index }">
          <span
            v-if="item.phosphate == null"
            class="text-add hover-add"
            @mouseover="colorItems.phosphate = '#258525'"
            @mouseout="colorItems.phosphate = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.phosphate }}
          </span>
        </template>
        <template v-slot:[`item.density`]="{ item, index }">
          <span
            v-if="item.density == null"
            class="text-add hover-add"
            @mouseover="colorItems.density = '#258525'"
            @mouseout="colorItems.density = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.density }}
          </span>
        </template>
        <template v-slot:[`item.urea`]="{ item, index }">
          <span
            v-if="item.urea == null"
            class="text-add hover-add"
            @mouseover="colorItems.urea = '#258525'"
            @mouseout="colorItems.urea = '#8d8d8d'"
          >
          ✘無資料
          </span>
          <span v-else class="hover-effect">
            {{ item.urea }}
          </span>
        </template>

      </v-data-table>
    </div>

    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="justify-center">
          請填入 {{ caseName }} 的尿液檢測時間
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  readonly
                  label="日期"
                  v-model="date"
                  type="date"
                  prepend-icon="mdi-calendar"
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="選擇時間"
                  v-model="time"
                  type="time"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="timeRules"
                  class="my-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="justify-space-around">
            <v-btn text color="red" @click="dialog = false">取消</v-btn>
            <v-btn text color="green" @click="submit">送出</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      headers: [
        {
          text: "姓名",
          value: "name",
          align: "center",
          width: "100px",
        },
        /* 尿液檢測欄位 by iki*/
        { text: "資料時間", value: "data_time", align: "center", width: "75px" },
        { text: "尿酸", value: "uric_acid", align: "center", width: "75px" },
        { text: "肌酸酐", value: "creatinine", align: "center", width: "75px" },
        { text: "尿素", value: "urea", align: "center", width: "75px" },
        { text: "鈉", value: "sodium", align: "center", width: "75px" },
        { text: "鎂", value: "magnesium", align: "center", width: "75px" },
        { text: "鈣", value: "calcium", align: "center", width: "75px" },
        { text: "磷酸根", value: "phosphate", align: "center", width: "75px" },
        { text: "檸檬酸根", value: "citrate", align: "center", width: "75px" },
        { text: "pH值", value: "ph", align: "center", width: "75px" },
        { text: "比重", value: "density", align: "center", width: "75px" },
        /* 尿液檢測欄位 by iki*/
      ],
      records: [],
      dialog: false,
      staff_id: "",
      case_id: "",
      index: "",
      caseName: "",
      date: "",
      time: "",
      colorItems: {
        data_time: "#8d8d8d",
        uric_acid: "#8d8d8d",
        calcium: "#8d8d8d",
        citrate: "#8d8d8d",
        creatinine: "#8d8d8d",
        magnesium: "#8d8d8d",
        sodium: "#8d8d8d",
        ph: "#8d8d8d",
        phosphate: "#8d8d8d",
        density: "#8d8d8d",
        urea: "#8d8d8d",
      },
      menu: false,
      selectedDate: new Date(),
      timeRules: [(v) => !!v || "此欄位不可為空"],
    };
  },
  methods: {
    async getList() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/attendance/list_uriki_data/?date=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.records = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    enterDataTime(index){
      this.index = index;
      this.getCurrentTime();
      this.caseName = this.records[index].name;
      this.case_id = this.records[index].case_num;
      this.dialog = true;
    },

    async submit(){
      if (!this.$refs.form.validate()) {
        return;
      }

      const axios = require("axios");
      const qs = require("qs");


      let formattedTime = moment(`${this.date}T${this.time}`).format();

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/attendance/import_uriki_data/${this.case_id}/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          datetime: formattedTime,
        }),
      };

      try {
        let res = await axios.request(config);
        if (res.data.status != 'success') {
          alert(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }

      this.getList();
      this.dialog = false;
    },

    /* Time Edit Block */
    getCurrentTime() {
      // 用 moment 處理 ISO8601 的字串
      // 分開處理日期與時間，因為日期與時間是分開的欄位
      // 日期會跟著選擇的日期變動
      // 時間基本上跟著本地時間走（夏令時間可能會有差異，但因為只用於顯示，不影響實際輸入時間）
      this.date = moment(this.selectedDate.toISOString()).format("YYYY-MM-DD");
      this.time = moment(new Date()).format("HH:mm");
      console.log(this.date + " " + this.time);
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    covertToLocalTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date
        .toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })
        .replace(",", ""); // 移除逗號
    },
    /* Time Edit Block End */ 
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    selectedDate() {
      this.getList();
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>

<style scoped>
.hover-effect:hover {
  cursor: pointer;
  color: #4477ce;
}
.hover-add:hover {
  cursor: pointer;
  color: #258525;
}
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.text-add {
  font-size: 12px;
  color: #8d8d8d;
}
</style>
