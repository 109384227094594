import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/Main.vue";
import Snapshot from "@/views/Snapshot.vue";
import TrafficSign from "@/views/TrafficSign.vue";
import Dashboard from "@/views/Dashboard.vue";
import Services from "@/views/Services.vue";
import Sixmwt from "@/views/Sixmwt.vue";
import SixmwtProfile from "@/components/SixmwtProfile.vue";
import SixmwtDashboard from "@/components/SixmwtDashboard.vue";
import CheckInSheet from "@/views/CheckInSheet.vue";
import CaseManagement from "@/views/CaseManagement.vue";
import CaseDailyRecords from "@/views/CaseDailyRecords.vue";
import MaterialManagement from "@/views/MaterialManagement.vue";
import MaterialSettlement from "@/views/MaterialSettlement.vue";
import WhiteBoard from "@/views/WhiteBoard.vue";
import ContactBook from "@/views/ContactBook.vue";
import LoginStaff from "@/views/LoginStaff.vue";
import LoginUser from "@/views/LoginUser.vue";
import EmployeeAttendance from "@/views/EmployeeAttendance.vue";
import TravelDashboard from "@/views/TravelDashboard.vue";
import CaseList from "@/views/CaseList.vue";
import StaffList from "@/views/StaffList.vue";
import PrimaryCareManagement from "@/views/PrimaryCareManagement.vue";
import ReImBurseMent from "@/views/ReImBurseMent.vue";
import EmergencyContact from "@/views/EmergencyContact.vue";
import UrineManagement from "@/views/UrineManagement.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/whiteboard",
  },
  {
    name: "login",
    path: "/login",
    component: LoginStaff,
  },
  {
    path: "/whiteboard",
    component: WhiteBoard,
  },
  {
    path: "/case-dashboard",
    component: Main,
  },
  {
    path: "/case-management",
    component: CaseManagement,
  },
  {
    path: "/case-daily-record",
    component: CaseDailyRecords,
  },
  {
    path: "/snapshot",
    component: Snapshot,
  },
  {
    path: "/material-management",
    component: MaterialManagement,
  },
  {
    path: "/material-settlement",
    component: MaterialSettlement,
  },
  {
    path: "/traffic",
    component: TrafficSign,
  },
  {
    path: "/checkinsheet",
    component: CheckInSheet,
  },
  {
    path: "/services/:id",
    component: Services,
  },
  {
    path: "/services/:id/dashboard",
    component: Dashboard,
  },
  {
    path: "/services/:id/sixmwt",
    component: Sixmwt,
    children: [
      {
        path: "",
        component: SixmwtProfile,
        name: "SixmwtProfile",
      },
      {
        path: "sixmwtdashboard",
        component: SixmwtDashboard,
        name: "SixmwtDashboard",
      },
    ],
  },
  {
    path: "/employee-attendance",
    component: EmployeeAttendance,
  },
  {
    path: "/travel-dashboard",
    component: TravelDashboard,
  },
  {
    path: "/case-list",
    component: CaseList,
  },
  {
    path: "/staff-list",
    component: StaffList,
  },
  {
    path: "/care-management",
    component: PrimaryCareManagement,
  },
  {
    name: "contactbook-login",
    path: "/contactbook/login",
    component: LoginUser,
  },
  {
    name: "contactbook",
    path: "/contactbook/:id",
    component: ContactBook,
  },
  {
    name: "reimbursement",
    path: "/reimbursement",
    component: ReImBurseMent,
  },
  {
    name: "emergencycontact",
    path: "/emergencycontact",
    component: EmergencyContact,
  },
  {
    name: "urine-management",
    path: "/urine-management",
    component: UrineManagement,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isStaffLogin = sessionStorage.getItem("isStaffLogin");
  const isUserLogin = sessionStorage.getItem("isUserLogin");
  const isContactBook = to.path.startsWith("/contactbook/");
  const isContactBookId = !to.path.endsWith("/login");
  if (isContactBook) {
    if (to.path === "/contactbook/login") {
      if (isUserLogin) {
        let id = sessionStorage.getItem("id");
        next("/contactbook/" + id);
      } else if (!isUserLogin) {
        next();
      }
    } else if (isContactBookId) {
      if (isUserLogin) {
        next();
      } else if (!isUserLogin) {
        next("/contactbook/login");
      }
    }
  } else if (!isContactBook) {
    if (to.path === "/login") {
      if (isStaffLogin) {
        next("/whiteboard");
      } else if (!isStaffLogin) {
        next();
      }
    } else {
      if (isStaffLogin) {
        next();
      } else if (!isStaffLogin) {
        next("/login");
      }
    }
  }
});

export default router;
