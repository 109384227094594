<template>
  <v-container>
    <v-card
      class="mx-auto my-2 pa-2"
      elevation="16"
      width="600"
      color="#F5EFE7"
    >
      <v-card-title class="dashboard-title">
        <div v-if="!end">
          <v-row no-gutters align="center" class="relative">
            <v-img
              src="@/assets/walk-unscreen.gif"
              class="img-titile"
              contain
            ></v-img>
            測試中‥‥
          </v-row>
        </div>
        <!-- </v-card-title>
      <v-card-title v-else class="justify-center font-weight-bold"> -->
        <div v-else>
          <v-row no-gutters align="center" class="relative">
            <v-img class="img-titile" src="@/assets/health-check.png" />
            測驗結果
          </v-row>
        </div>
      </v-card-title>
      <v-card-text class="pa-2">
        <div v-if="!end">
          <v-row no-gutters>
            <v-col cols="6">
              <div class="dashboard-subtitle">行走時間</div>
              <div class="dashboard-content">{{ time | formatTime }}</div>
            </v-col>
            <v-col cols="6">
              <div class="dashboard-subtitle">行走距離</div>
              <div class="dashboard-content">{{ currentDistance }} m</div>
            </v-col>
          </v-row>
        </div>
        <v-card elevation="0" v-else color="#F5EFE7">
          <v-row no-gutters class="text-center">
            <v-col cols="12">
              <div class="dashboard-subtitle text-h6">總行走距離</div>
              <div class="pa-2 dashboard-content">{{ totalDistance }} m</div>
            </v-col>
            <v-col cols="12">
              <div class="dashboard-subtitle text-h6">總行走速度</div>
              <div class="pa-2 dashboard-content">{{ speed }} m/s</div>
            </v-col>
            <v-col cols="12">
              <div class="dashboard-subtitle text-h6">心肺能力障礙</div>
              <div class="pa-2 dashboard-content">{{ severity }}</div>
            </v-col>
            <v-col cols="12">
              <div class="dashboard-subtitle text-h6">建議</div>
              <div
                class="pa-2 mx-auto text-start dashboard-content"
                style="width: 50%"
              >
                {{ suggestion }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions v-if="!end">
        <v-row no-gutters>
          <v-col cols="12" class="pa-2">
            <div class="background-half-round">
              <div class="speed-number-wapper">
                <div class="speed-number speed-0">
                  <span>0</span>
                </div>
                <div class="speed-number speed-20">
                  <span>0.3</span>
                </div>
                <div class="speed-number speed-40">
                  <span>0.6</span>
                </div>
                <div class="speed-number speed-60">
                  <span>0.9</span>
                </div>
                <div class="speed-number speed-80">
                  <span>1.2</span>
                </div>
                <div class="speed-number speed-100">
                  <span>1.5</span>
                </div>
                <div class="speed-number speed-120">
                  <span>1.8</span>
                </div>
              </div>
              <div
                class="pointer"
                :style="`transform: rotate(${pointerRotation}deg)`"
              ></div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="text-center pa-2 text-h6" style="color: #213555">
              行走速度：{{ speed }} m/s
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      height: "",
      weight: "",
      age: "",
      gender: "",
      url: "",
      messages: [],
      ws: null,
      pointerRotation: -90,
      time: 0,
      currentDistance: 0,
      totalDistance: 0,
      speed: "0.0",
      severity: "",
      suggestion: "",
      end: false,
    };
  },
  filters: {
    formatTime(value) {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
  methods: {
    // connectWebSocket() {
    //   this.ws = new WebSocket(this.url);
    //   this.ws.onmessage = this.handleMessage;
    // },
    // clossWebSocket() {
    //   if (this.ws) {
    //     this.ws.close();
    //   }
    // },
    handleMessage(event) {
      const data = JSON.parse(event.data);
      // this.messages = data;
      this.currentDistance = data.current_distance;
      this.time = data.duration;
      this.speed = data.speed;
      this.rotatePointer(this.speed);
      if (data.final) {
        this.totalDistance = data.total_distance;
        this.severity = data.severity;
        this.suggestion = data.suggestion;
        this.end = true;
      }
      // console.log(this.messages);
    },
    // 儀表板設計
    startAnimation() {
      setInterval(() => {
        const speed = Math.random() * 1.8;
        this.rotatePointer(speed);
      }, 1000);
    },
    rotatePointer(speed) {
      // 根据速度值计算指针旋转角度
      const rotation = speed * 100 - 90;
      this.pointerRotation = rotation;
    },
  },
  mounted() {
    this.height = this.$route.params.height;
    this.weight = this.$route.params.weight;
    this.age = this.$route.params.age;
    this.gender = this.$route.params.gender;
    if (this.height == undefined) {
      this.$router.push({
        name: "SixmwtProfile",
      });
    }
    this.url =
      "ws://192.168.68.51:8000/working_test_1/?height=" +
      this.height +
      "&weight=" +
      this.weight +
      "&age=" +
      this.age +
      "&gender=" +
      this.gender;
    // this.connectWebSocket();
    // 模擬數據
    // this.startAnimation();
  },
  beforeDestroy() {
    this.clossWebSocket();
  },
};
</script>

<style scoped>
.background-half-round {
  width: 300px;
  height: 150px;
  background: #232323;
  margin: auto;
  border-radius: 300px 300px 0 0;
  border: 10px solid #151515;
  border-bottom: 0;
  box-shadow: inset 0 2px 5px #151515;
  position: relative;
}

.background-half-round:after {
  content: "";
  width: 50px;
  height: 25px;
  position: absolute;
  background: #2f2f2f;
  display: block;
  top: auto;
  bottom: 0;
  border-radius: 50px 50px 0 0;
  box-shadow: inset 0px 3px 5px 0px #151515;

  z-index: 5;
  margin: auto;
  left: 0;
  right: 0;
}

.speed-number-wapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.speed-number {
  color: #fff;
  font-family: "Roboto";
}

.speed-number span:after {
  content: "";
  width: 2px;
  height: 5px;
  background: #fff;
  position: relative;
}

.speed-0,
.speed-20,
.speed-40,
.speed-80,
.speed-100,
.speed-120 {
  position: absolute;
  bottom: 0;
  width: 50%;
}

.speed-0 span {
  padding: 0px 0px 0px 5px;
}

.speed-20 {
  transform: rotate(30deg);
  transform-origin: 100% 0%;
}

.speed-20 span {
  transform: rotate(-30deg);
  display: inline-block;
  padding: 15px 0px 0px 15px;
}

.speed-40 {
  transform: rotate(60deg);
  transform-origin: 100% 0%;
}

.speed-40 span {
  transform: rotate(-60deg);
  display: inline-block;
  padding: 0px 0px 0px 20px;
}

.speed-60 {
  margin: auto;
  text-align: center;
}

.speed-80 {
  transform: rotate(120deg);
  transform-origin: 100% 0%;
}

.speed-80 span {
  transform: rotate(-120deg);
  display: inline-block;
  padding: 0px 20px 0px 20px;
}

.speed-100 {
  transform: rotate(150deg);
  transform-origin: 100% 25%;
}

.speed-100 span {
  transform: rotate(-150deg);
  display: inline-block;
  padding: 0px 15px 0px 15px;
  color: #ff4141;
  font-weight: bold;
}

.speed-120 {
  transform: rotate(180deg);
  transform-origin: 100% 50%;
}

.speed-120 span {
  transform: rotate(-180deg);
  display: inline-block;
  padding: 0px 5px 0px 0px;
  color: #ff4141;
  font-weight: bold;
}

.dashboard-icon {
  width: 20px;
}

.pointer {
  background: #ffa500;
  width: 8px;
  height: 140px;
  border-radius: 10px;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -ms-transform-orgin: 50% 100%;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-orgin: 50% 100%;
  margin: auto;
  transform-origin: 50% 100%;

  transition: transform 0.5s linear;
}

.dashboard-title {
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  color: #213555;
}
.dashboard-subtitle {
  text-align: center;
  font-size: 20px;
  color: #4f709c;
}
.dashboard-content {
  text-align: center;
  font-size: 16px;
  color: #8b6e46;
}
.img-titile {
  position: absolute;
  max-width: 70px;
  right: 100px;
}
.relative {
  position: relative;
}
/* 測試動畫 */
/* .speed-ani {
  animation: speeding 5s infinite alternate;
}

@keyframes speeding {
  0% {
    transform: rotate(-90deg);
  }
  5% {
    transform: rotate(-85deg);
  }
  80% {
    transform: rotate(80deg);
  }
  85% {
    transform: rotate(85deg);
  }
  90% {
    transform: rotate(80deg);
  }
  95% {
    transform: rotate(90deg);
  }
  97% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(92deg);
  }
} */
</style>
