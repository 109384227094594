<template>
  <div class="pa-2 custom-margin">
    <v-card>
      <v-card-title class="pa-2">
        <v-row class="pa-2" justify="center" align="center">
          <v-col cols="12" sm="12" md="4">
            <div class="font-weight-bold mx-2">當日簽到退清單</div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field
                class="pa-0"
                filled
                rounded
                dense
                v-model="date"
                @click="openPicker"
                readonly
                hide-details
              >
                <template v-slot:append>
                  <v-btn icon @click="previousDay">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon @click="nextDay">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field
                class="pa-0"
                outlined
                dense
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="搜尋"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="data"
        :search="search"
        no-data-text=""
        class="elevation-1 custom-table"
      >
        <!-- <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="index" :class="h.class">
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template> -->
        <template v-slot:item="i">
          <tr>
            <td class="td1">{{ i.item.name }}</td>
            <td class="td2">{{ i.item.get_on }}</td>
            <td class="td3">{{ i.item.check_in }}</td>
            <td class="td4">{{ i.item.check_out }}</td>
            <td class="td5">{{ i.item.get_off }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showPicker" persistent max-width="290">
      <v-date-picker
        v-model="pickerDate"
        @input="closePicker"
        no-title
      ></v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      search: "",
      pickerDate: new Date().toISOString().slice(0, 10),
      date: new Date().toISOString().slice(0, 10).replaceAll("-", "/"),
      showPicker: false,
      headers: [
        {
          text: "姓名",
          value: "name",
        },
        {
          text: "上車時間",
          value: "get_on",
        },
        {
          text: "簽到時間",
          value: "check_in",
        },
        {
          text: "簽退時間",
          value: "check_out",
        },
        {
          text: "下車時間",
          value: "get_off",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios
        .get(
          "/api/attendance/list_today_log?datetime=" +
            this.date
        )
        .then((res) => {
          this.data = res.data.data;
          console.log(this.data);
        })
        .catch((error) => {
          console.error("failed", error);
        });
    },
    openPicker() {
      this.showPicker = !this.showPicker;
    },
    closePicker() {
      this.showPicker = false;
      console.log(this.pickerDate);
    },
    previousDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() - 1);
      let options = {
        timeZone: "Asia/Taipei",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      let formattedDate = currentDate.toLocaleString("zh-TW", options);
      this.date = formattedDate.replaceAll("-", "/");
      this.getList();
    },
    nextDay() {
      const currentDate = new Date(this.date);
      currentDate.setDate(currentDate.getDate() + 1);
      let options = {
        timeZone: "Asia/Taipei",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      let formattedDate = currentDate.toLocaleString("zh-TW", options);
      this.date = formattedDate.replaceAll("-", "/");
      this.getList();
    },
  },
};
</script>

<style>
.elevation-1 {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.custom-margin {
  margin: auto;
  max-width: fit-content;
}
.custom-table {
  padding: 8px;
}
.v-data-table-header tr {
  background: #fafafa;
}
.td1 {
  font-size: 16px !important;
  font-weight: bold;
  background: #f8f6f7;
}
.td2 {
  font-size: 16px !important;
  background: #f2e9eb;
}
.td3 {
  font-size: 16px !important;
  background: #f3d9e3;
}
.td4 {
  font-size: 16px !important;
  background: #fdcedf;
}
.td5 {
  font-size: 16px !important;
  background: #f2bed1;
}
.v-input__append-inner {
  margin-top: 4px !important;
}
</style>
