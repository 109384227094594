<template>
  <v-app>
    <v-container>
      <v-btn color="primary" @click="getLocation"> 打卡上/下班 </v-btn>
    </v-container>
  </v-app>
</template>

<script>
export default {
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const now = new Date();
            const datetime = `${now.getFullYear()}-${
              now.getMonth() + 1
            }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            alert(
              `當前時間: ${datetime}\n經度: ${position.coords.longitude}\n緯度: ${position.coords.latitude}`
            );
          },
          (error) => {
            alert("無法獲取位置信息，錯誤代碼: " + error.code);
          }
        );
      } else {
        alert("無法取得定位資訊。");
      }
    },
  },
};
</script>
