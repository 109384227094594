<template>
  <div id="app" class="web-camera-container mx-auto">
    <div class="pa-2">
      <v-form ref="form">
        <v-text-field
          dense
          outlined
          clearable
          validate-on-blur
          hide-details="auto"
          label="請輸入姓名"
          v-model="name"
          :rules="rules.notNull"
        ></v-text-field>
      </v-form>
    </div>
    <div class="camera-button" v-if="!isCameraOpen">
      <button
        type="button"
        class="button is-rounded is-success"
        @click="onButtonClick"
      >
        <span>選擇照片</span>
      </button>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChange"
      />
    </div>
    <div class="camera-button" v-if="!isLocal">
      <button
        type="button"
        class="button is-rounded"
        :class="{ 'is-link': !isCameraOpen, 'is-danger': isCameraOpen }"
        @click="toggleCamera"
      >
        <span v-if="!isCameraOpen">開啟相機</span>
        <span v-else>關閉相機</span>
      </button>
    </div>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div v-if="isLocal" class="camera-box" :class="{ flash: isLocal }">
      <v-img contain :src="imageUrl" :width="450" :height="337.5" />
    </div>
    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        v-show="!isPhotoTaken"
        ref="camera"
        :width="450"
        :height="337.5"
        autoplay
      ></video>

      <canvas
        v-show="isPhotoTaken"
        id="photoTaken"
        ref="canvas"
        :width="450"
        :height="337.5"
      ></canvas>
    </div>

    <div v-if="isCameraOpen && !isLoading && !isPicture" class="camera-shoot">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <button
            type="button"
            class="button"
            @click="takePhoto"
            v-bind="attrs"
            v-on="on"
          >
            <img
              src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
            />
          </button>
        </template>
        <span>拍照</span>
      </v-tooltip>
    </div>
    <div v-if="isCameraOpen && !isLoading && isPicture" class="camera-shoot">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <button
            type="button"
            class="button"
            @click="
              (isPicture = false), (isPhotoTaken = false), (isSnapShot = false)
            "
            v-bind="attrs"
            v-on="on"
          >
            <img
              src="https://img.icons8.com/material-outlined/50/000000/video-call--v2.png"
            />
          </button>
        </template>
        <span>開啟相機</span>
      </v-tooltip>
    </div>

    <div
      v-if="isPhotoTaken && isCameraOpen && isSnapShot"
      class="camera-download"
    >
      <a id="downloadPhoto" class="button" role="button" @click="downloadImage">
        上傳照片
      </a>
    </div>
    <div v-if="isLocal" class="pa-2">
      <a class="button" role="button" @click="uploadLocalImage">上傳照片</a>
    </div>
    <div v-if="isLocal" class="pa-2">
      <v-btn text color="red" @click="(isLocal = false), (name = '')">
        回上一頁
      </v-btn>
    </div>

    <v-dialog v-model="isSucceed" max-width="300">
      <v-card>
        <v-card-title class="justify-center font-weight-bold">
          上傳成功！
        </v-card-title>
        <v-card-text class="pa-4 text-center">
          <v-icon size="100" color="green">mdi-check-circle-outline</v-icon>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn block text color="blue" @click="isSucceed = false">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import FormData from "form-data";

export default {
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      isPicture: false,
      isSnapShot: false,
      isLocal: false,
      isSucceed: false,
      name: "",
      imageUrl: null,
      rules: { notNull: [(v) => !!v || "此欄位為必填。"] },
    };
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.isPicture = false;
        this.isSnapShot = false;
        this.name = "";
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      this.isPicture = !this.isPicture;
      this.isSnapShot = !this.isSnapShot;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },

    downloadImage() {
      if (this.$refs.form.validate()) {
        const download = document.getElementById("downloadPhoto");
        console.log(download);
        document.getElementById("photoTaken").toBlob((blob) => {
          console.log(blob);
          const data = new FormData();
          let headers = {
            "Content-Security-Policy": "upgrade-insecure-requests",
          };
          // let headers = {
          //   'Accept': '*/*',
          //   'Accept-Encoding': 'gzip, deflate, br',
          //   'Connection': 'keep-alive',
          // };

          // let headers['Content-Type'] = 'multipart/form-data; boundary=';
          // let headers['Host'] = window.location.protocol + "//" + window.location.host;
          // let headers['Content-Length'] =

          data.append("file", blob, this.name + ".jpg");

          let config = {
            method: "post",
            url: "/api/attendance/upload",
            headers: headers,
            data: data,
          };
          try {
            let res = axios(config);
            console.log(res);
            this.isSucceed = true;
          } catch (error) {
            console.log(error);
          }
        }, "image/jpeg");
        // .toDataURL("image/jpeg");
        // .replace("image/jpeg", "image/octet-stream");
        // download.setAttribute("href", canvas);
        // const axios = require("axios");
        // const FormData = require("form-data");
      }
    },
    async uploadLocalImage() {
      if (this.$refs.form.validate()) {
        const axios = require("axios");
        const FormData = require("form-data");
        const data = new FormData();
        const res = await fetch(this.imageUrl);
        const blob = await res.blob();
        data.append("file", blob, this.name + ".jpg");
        let config = {
          method: "post",
          url: "/api/attendance/upload",
          headers: {},
          data: data,
        };

        try {
          let res = await axios(config);
          console.log(res);
          this.isSucceed = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
    createImage(file) {
      for (let n = 0; n < file.target.files.length; n++) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
          this.isLocal = !this.isLocal;
        };
        reader.readAsDataURL(file.target.files[n]);
      }
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
      this.$refs.uploader.value = "";
    },
    onButtonClick() {
      this.$refs.uploader.click();
      this.isLocal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.5/css/bulma.min.css");
#app {
  display: flex;
}

body {
  display: flex;
  justify-content: center;
}

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
  height: 100%;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 8rem 0 0 -2.5rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
