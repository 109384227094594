<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <!-- <v-btn color="#141E46" class="white--text"> 本週 </v-btn> -->
      <div class="mr-2">
        <v-btn @click="menu = true" color="#BB2525" class="white--text">
          {{ displayDateRange }}
        </v-btn>
        <v-menu v-model="menu" :close-on-content-click="false" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on"></div>
          </template>
          <v-date-picker v-model="range" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">取消</v-btn>
            <v-btn text color="primary" @click="saveDates">確定</v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <v-btn
        tile
        color="#9AB42A"
        class="white--text"
        elevation="0"
        @click="openDialog"
      >
        編輯金額
      </v-btn>
      <div class="mx-2">
        <v-btn
          tile
          color="#813d67"
          class="white--text"
          elevation="0"
          @click="exportReport"
        >
          匯出報表
        </v-btn>
      </div>
      <!-- <div class="mx-2">
        <v-btn
          tile
          color="#3d6781"
          class="white--text"
          elevation="0"
          @click="exportRecipt"
        >
          列印收據
        </v-btn>
      </div> -->
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :search="filter_text"
        no-results-text="無搜尋的資料"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-text-field
            v-model="filter_text"
            label="搜尋"
            class="mx-4"
          ></v-text-field>
        </template>

        <!-- show data and edit each cell -->
        <template v-slot:item="{ item, index }">
          <tr>
            <td v-for="header in headers" :key="header.value">
              <!-- 除了 養護費, 補助, 藥品, 住院 之外不可編輯-->
              <v-edit-dialog
                v-if="['養護費', '補助', '藥品', '住院'].includes(header.text)"
                large
                :return-value.sync="item[header.value]"
                @save="
                  enterPrice(item.case_num, header.value, item[header.value])
                "
              >
                <span
                  @mouseover="colorItems = '#258525'"
                  class="d-flex align-center justify-center"
                >
                  <!-- Show "✚新增" if the value is null or 0 -->
                  <span
                    v-if="item[header.value] == null || item[header.value] == 0"
                    class="text-add hover-add"
                  >
                    ✚新增
                  </span>

                  <!-- Otherwise, show the value -->
                  <span v-else>
                    {{ item[header.value] }}
                  </span>
                </span>

                <!-- Editable input field -->
                <template v-slot:input>
                  <v-text-field
                    v-model="item[header.value]"
                    type="number"
                    single-line
                    hide-details
                  ></v-text-field>
                </template>
              </v-edit-dialog>

              <!-- If header is not editable, just show plain text -->
              <span v-else>{{ item[header.value] }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title> 衛材金額編輯 </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="price in prices" :key="price.id" cols="6" sm="3">
                <v-text-field
                  v-model="price.price"
                  :label="price.name"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelUpdate"> 取消 </v-btn>
          <v-btn color="blue darken-1" text @click="updatePrices"> 確認 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const today = new Date();
    const nextWeek = new Date(new Date().setDate(new Date().getDate() + 6));
    return {
      headers: [
        { text: "姓名", value: "name" },
        { text: "總金額", value: "totalPrice" },
      ],
      items: [],
      prices: [],
      materials: [],
      dialog: false,
      menu: false,
      exist: false,
      range: [
        today.toISOString().substr(0, 10),
        nextWeek.toISOString().substr(0, 10),
      ],
      res_data: [],
      filter_text: "",
    };
  },
  mounted() {
    this.fetchData();
    this.fetchPrice();
  },
  computed: {
    displayDateRange() {
      return `${this.range[0]} - ${this.range[1]}`;
    },
  },
  methods: {
    exportReport() {
      // const data = this.res_data;
      // let csvContent = "\uFEFF";
      // // headers
      // data[0].items.forEach((item, index) => {
      //   csvContent +=
      //     (index === 0 ? "長輩名稱," : "") + item.material_name + ",價錢,";
      // });
      // csvContent += "加總價錢\n";
      // // items
      // data.forEach((data) => {
      //   let row = data.name + ",";
      //   data.items.forEach((item) => {
      //     row += item.quantity_used + "," + item.price + ",";
      //   });
      //   row += data.total_price;
      //   csvContent += row + "\n";
      // });
      // // download
      // const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      // const link = document.createElement("a");
      // link.setAttribute("href", encodedUri);
      // link.setAttribute(
      //   "download",
      //   `衛材結算表_${this.range[0]}-${this.range[1]}.csv`
      // );
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      const fileUrl = `/api/warehouse/generate_csv?start=${this.range[0]}&end=${this.range[1]}`;
      const link = document.createElement("a");
      link.setAttribute("href", fileUrl);
      link.setAttribute(
        "download",
        `衛材收據_${this.range[0]}-${this.range[1]}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportRecipt() {},
    saveDates() {
      this.menu = false;
    },
    formatDate(date) {
      return date; // 格式化日期，這裡僅返回原值，可根據需要調整格式
    },
    openDialog() {
      this.dialog = true;
    },
    cancelUpdate() {
      this.dialog = false;
    },
    async fetchData() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/warehouse/list_usage?start=${this.range[0]}&end=${this.range[1]}`,
        header: {},
      };

      try {
        let res = await axios.request(config);
        this.res_data = res.data.data;
        console.log(res.data.data);
        this.processData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPrice() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: "/api/warehouse/materials/",
        header: {},
      };

      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.prices = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updatePrices() {
      this.dialog = false;
      const axios = require("axios");
      this.materials = this.prices.map((item) => ({
        id: item.id,
        name: item.name,
        price: Number(item.price),
      }));
      let data = JSON.stringify({
        start: this.range[0],
        end: this.range[1],
        materials: this.materials,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/warehouse/change_price",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        if (res.data.status == 200) {
          this.fetchData();
          this.fetchPrice();
        }
      } catch (error) {
        console.log(error);
      }
    },
    processData(data) {
      const dynamicHeaders = new Set();
      data.forEach((person) => {
        person.items.forEach((item) => {
          dynamicHeaders.add(item.material_name);
        });
      });
      this.headers = [
        { text: "姓名", value: "name", align: "center", width: "90px" },
        ...Array.from(dynamicHeaders).map((h) => ({
          text: h,
          value: h,
          align: "center",
          width: "90px",
        })),
        {
          text: "養護費",
          value: "careFee",
          align: "center",
          width: "85px",
        },
        {
          text: "補助",
          value: "subSidy",
          align: "center",
          width: "85px",
        },
        {
          text: "藥品",
          value: "medicationExpense",
          align: "center",
          width: "85px",
        },
        {
          text: "住院",
          value: "hospitalizationExpense",
          align: "center",
          width: "85px",
        },
        {
          text: "總金額",
          value: "totalPrice",
          align: "center",
          width: "85px",
        },
      ];
      this.items = data.map((person) => {
        const itemRecord = {
          name: person.name,
          case_num: person.case_num,
          totalPrice: person.total_price,
          careFee: person.care_fee,
          subSidy: person.subsidy,
          medicationExpense: person.medication_expense,
          hospitalizationExpense: person.hospitalization_expense,
        };
        person.items.forEach((item) => {
          itemRecord[item.material_name] = item.quantity_used;
        });
        return itemRecord;
      });
    },
    async enterPrice(case_num, header_value, item_value) {
      const axios = require("axios");
      const qs = require("qs");

      let data = qs.stringify({
        case_num: case_num,
        option: header_value,
        price: item_value,
      });

      let config = {
        method: "post",
        url: `/api/warehouse/update_monthly_expense`,
        maxBodyLength: Infinity,
        data: data,
        header: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      try {
        let res = await axios.request(config);
        console.log(res.data.data);
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    range() {
      this.fetchData();
      this.fetchPrice();
    },
    items: {
      deep: true, // 深度監聽
      handler(newItems) {
        newItems.forEach((item) => {
          // 強制將每個欄位轉換為數值型態
          const careFee = Number(item.careFee) || 0;
          const subSidy = Number(item.subSidy) || 0;
          const medicationExpense = Number(item.medicationExpense) || 0;
          const hospitalizationExpense =
            Number(item.hospitalizationExpense) || 0;
          console.log(item);
          // 計算總金額
          item.totalPrice =
            careFee + subSidy + medicationExpense + hospitalizationExpense;
          console.log(item.totalPrice);
        });
      },
    },
  },
};
</script>

<style>
.custom-margin {
  overflow-x: auto !important;
}
.table-row {
  width: 90vw;
}

.table {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.v-data-table-header th {
  font-size: 18px !important;
  min-width: 120px !important;
}

.v-data-table .v-data-table__wrapper tbody tr td {
  text-align: center !important;
  font-size: 18px !important;
}
</style>
