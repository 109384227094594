<template>
  <v-container class="custom-margin">
    <v-card class="contact-card">
      <v-card-title class="justify-center custom-title"
        >急診聯繫單</v-card-title
      >
      <v-form>
        <v-container>
          <!-- 第一層主標題（送醫前必要資訊、此次就醫、基本資料、身體評估） -->
          <v-row dense v-for="(content, key) in contents" :key="key">
            <v-container class="level1">
              {{ content.title }}

              <!-- 第二層標題 (description) -->
              <v-container
                v-for="(section, contentIndex) in content.sections"
                :key="contentIndex"
              >
                <v-col cols="12" justify="center">
                  <span class="custom-section">
                    {{ section.description }}
                  </span>
                </v-col>

                <v-col class="content-padding" justify="center">
                  <v-row dense :key="contentIndex">
                    <!-- 第三層之後，使用元件處理 -->
                    <v-col
                      :cols="12"
                      :sm="6"
                      :md="section.questions.length < 4 ? 12 / section.questions.length : 3"
                      v-for="(question, questionIndex) in section.questions"
                      :key="questionIndex"
                      class="content-question"
                    >
                      <!-- TODO: border 拿掉 -->
                      <EmergencyContactComponent
                        :question="question"
                      ></EmergencyContactComponent>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-container>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions class="justify-center">
        <v-btn class="mx-4" color="blue darken-1" text @click="submitForm"
          >送出</v-btn
        >
        <v-btn class="mx-4" color="red darken-1" text @click="clearAnswers"
          >清空</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import EmergencyContactComponent from "@/components/EmergencyContactComponent.vue"; // 引入剛創建的子組件
export default {
  name: "EmergencyContact",
  components: { EmergencyContactComponent },
  data() {
    return {
      headers: [],
      questionnaire: null,
      menu: false,
      selectedDate: new Date(),
      add_record_dialog: false,
      contents: {},
      view_date: "",
      window_width: "",
    };
  },
  methods: {
    async getQuestions() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/get_emergency_contact_question?name=EMER_001`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.contents = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async makeRecord(case_num) {
      const axios = require("axios");
      let data = JSON.stringify({
        case_num: case_num,
        employee_id: this.staff_id,
        date: this.formattedDate,
        ...this.contents,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/journal/make_record",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(`${res.data.status}, ${res.data.data}`);
        this.getList();
      } catch (error) {
        console.log(error);
      }
    },

    viewDailyRecord(item) {
      this.selected_case_num = item.case_num;
      this.selected_case_name = item.name;
      this.view_record_dialog = true;
      this.getRecordList(this.selected_case_num);
    },
    addRecord(item) {
      this.selected_case_name = item.name;
      this.case_num = item.case_num;
      this.getRecord(this.case_num, this.formattedDate);
      this.add_record_dialog = true;
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    submitForm() {
      this.makeRecord(this.case_num);
    },
    submitUpload() {
      console.log(this.imagePreviews);
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${month}/${day}`;
    },
    formatTime(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    clearAnswers() {
      this.getQuestions();
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getQuestions();
  },
  watch: {},
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>
<style scoped>
/* 整體畫面初始設定 */
.custom-margin {
  overflow-x: auto !important;
  overflow-y: hidden;
  max-width: fit-content;
  max-width: 100%;
  width: 100%;
  font-size: 16px; /* Set default size is 16 px*/
}

/* 表單卡片樣式設定 (最外層) */
.contact-card {
  border-radius: 20px;
  margin: 1em;
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
  background-color: antiquewhite;
}

/* 表單名稱設定：急診聯繫單 */
.custom-title {
  color: #0079ff;
  font-weight: bold;
  font-size: 2em;
}

.level1 {
  font-size: 1.5em;
  font-weight: bold;
  color: #336ca4;
}

.level2 {
  font-size: 1em;
  font-weight: bold;
  color: #34495e;
}

.custom-section {
  text-align: center;
  color: black;
}

.content-question {
  border: 1px solid gray;
  border-radius: 20px;
}

/* 表單內容的padding */
.content-padding {
  padding: 1rem;
}

/* 以下為 RWD 設定 */

/* 桌面設定 */
@media (min-width: 1024px) {
  .custom-margin {
    width: 80%;
  }
}

/* 平板設定 */
@media (max-width: 1024px) {
  .custom-title {
    font-size: 1.5em;
  }
  .level1 {
  }
  .level2 {
  }
}

/* 手機設定(直式) */
@media (max-width: 768px) {
  .custom-title {
    font-size: 1.5em;
  }
  .level1 {
    font-size: 1.25em;
  }
  .level2 {
    font-size: 1em;
  }
}
</style>
